.Header {
  padding: 1rem 0;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      display: flex;
      font-weight: bold;
      font-size: 1.6rem;
      position: relative;
      cursor: pointer;

      .Typewriter {
        .Typewriter__wrapper {
          display: inline-flex;
          align-items: center;
          gap: 0.3rem;

          u,
          span {
            margin-left: -5px;
          }
        }
      }
    }

    nav {
      display: flex;
      gap: 3rem;
    }
  }
}

// phones
@media (max-width: 600px) {
  .Header {
    .wrapper {
      .logo {
        font-size: 1.3rem;
      }
    }
  }
}

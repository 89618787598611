.work-item {
  border: solid 1px #000;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.8rem;
  transition: 0.3s;

  .top {
    h3 {
      font-size: 26px;
      font-weight: normal;
      margin: 0;
    }
  }

  .footer {
    font-size: 14px;
    padding-top: 0;
    padding-bottom: 0;
    border-top: dotted 2px #a1a1a1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.6rem;

    .footer-item {
      a {
        .icon {
          margin-right: 0.5rem;
        }

        .text {
          margin-right: 0.2rem;
        }
      }

      &.category-icon {
        max-width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 1.4rem;
        }
      }
    }
  }

  &:hover {
    background: #333;
    color: #fff;

    .top {
      p {
        color: #fff;
      }
    }

    .footer {
      .footer-item {
        &.category-icon {
          img {
            filter: invert(1)
          }
        }
      }
    }
  }
}

.Work {
  margin: 8rem 0;

  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    .heading {
      // padding: 0.8rem;
      // background: rgba(0, 0, 0, 0.03);
      // border: dotted 1px #333;

      h2 {
        font-size: 56px;
        font-weight: 200;
        margin: 0;
        line-height: 56px;
      }
    }

    .more {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      .content {
        h4 {
          font-size: 26px;
          font-weight: normal;
          margin-bottom: 0;
        }

        p {
          margin-bottom: 0;

          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

// phones
@media (max-width: 600px) {
  .Work {
    .wrapper {
      grid-template-columns: repeat(1, 1fr);

      .more {
        display: block;
      }
    }
  }
}

.Home {
  margin: 10rem 0;

  .wrapper {
    h1 {
      font-weight: normal;
      line-height: 44px;
    }

    .emoji {
      display: inline-block;
      transform-origin: bottom right;
      animation: waving 1s infinite;
    }

    // .emoji {
    //   display: inline-block;
    //   max-width: 36px;
    //   margin-bottom: -3px;

    //   img {
    //     margin-bottom: -10px;
    //   }
    // }
  }
}

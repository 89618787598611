* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  // outline: solid 1px red;
}

body {
  margin: 0;
  font-family: "Bricolage Grotesque", sans-serif;
  // font-size: 15px;
  background: #e8e6e2;
  color: #333;
  line-height: 160%;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
}

// p {
//   line-height: 24px;
// }

.half {
  width: 50%;
}

.wrapper {
  max-width: 1000px;
  margin: auto;
  padding: 0 2rem;
}

.accent-color {
  color: #ff5218;
}

.underline {
  position: relative;
}

.underline::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: dotted #000 2px;
}

.img-div {
  line-height: 0;
}

img {
  width: 100%;
}

@keyframes waving {
  0% {
    transform: rotate(-8deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-8deg);
  }
}

.Footer {
  background: #333;
  color: #e8e6e2;
  padding: 5rem 0;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    text-align: center;

    h2 {
      font-size: 1rem;
      font-weight: 200;
      margin: 0;
    }

    a {
      font-weight: 200;
      font-size: 56px;
      line-height: 56px;
    }

    .socials {
      display: flex;
      align-items: center;

      a {
        transition: 0.3s;
        padding: 0 1rem;
        display: inline-block;

        i {
          font-size: 2rem;
        }

        &.upwork {
          img {
            max-width: 40px;
            margin-bottom: -5px;
          }
        }

        &:hover {
          text-decoration: none;
          transform: translateY(-3px);
        }
      }
    }
  }
}

// phones
@media (max-width: 600px) {
  .Footer {
    .wrapper {
      a {
        font-size: 28px;
      }

      .socials {
        a {
          padding: 0 0.5rem;

          &.upwork {
            img {
              margin-bottom: -8px;
            }
          }
        }
      }
    }
  }
}
